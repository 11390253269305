var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.translateTitle("操作记录"),
        visible: _vm.dialogVisible,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("TablePaging", {
        attrs: {
          "column-list": _vm.columnList,
          "is-index": true,
          "is-pagination": false,
          loading: _vm.loading,
          "page-info": _vm.pageInfo,
          "table-data": _vm.list,
        },
        on: { handleCurrentChange: _vm.handleCurrentChange },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "content-info" },
                  [
                    row.head
                      ? _c("el-image", {
                          attrs: {
                            "preview-src-list": [row.head],
                            src: row.head,
                          },
                        })
                      : _vm._e(),
                    _c("p", { staticClass: "content-name" }, [
                      _vm._v(_vm._s(row.name)),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "create_time",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("dateFormat")(row.create_time, true))),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="translateTitle('图片详情')"
    :visible.sync="dialogVisible"
    width="50%"
  >
    <!-- 表格 -->
    <TablePaging
      :column-list="columnList"
      :is-index="true"
      :is-pagination="false"
      :loading="loading"
      :page-info="pageInfo"
      :table-data="list"
      @handleCurrentChange="handleCurrentChange"
    >
      <!-- 内容 -->
      <template #image="{ row }">
        <el-image
          v-if="row.pic.length"
          :preview-src-list="[row.pic[0].url]"
          :src="row.pic[0].url"
        />
      </template>
      <template #address="{ row }">
        <p>{{ row.address || '--' }}</p>
        <p>经度：{{ row.baidu_longitude || '--' }}</p>
        <p>纬度：{{ row.baidu_latitude || '--' }}</p>
      </template>
    </TablePaging>
  </el-dialog>
</template>

<script>
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import { translateTitle } from '@/utils/i18n'
  import { getAttachmentExt } from '@/api/audit'
  export default {
    components: {
      TablePaging,
    },
    mixins: [tableMixins],
    data() {
      return {
        dialogVisible: false,
        columnList: [
          { label: '图片', slotName: 'image' },
          { label: '拍摄地点', slotName: 'address' },
          { label: '拍摄时间', prop: 'shoot_time' },
        ],
      }
    },
    methods: {
      translateTitle,
      handleOpen(val) {
        this.getTableData(val)
        this.dialogVisible = true
      },
      // 确定
      async getTableData(val) {
        const params = {
          aids: val.pics,
        }
        this.loading = true
        const res = await getAttachmentExt(params)
        this.loading = false
        this.list = res.list
      },
    },
  }
</script>
<style lang="scss" scoped>
  $table-info: '.content-info';
  ::v-deep .el-dialog {
    #{$table-info} {
      display: flex;
      align-items: center;
    }
    .content-name {
      margin-left: 10px;
    }
  }
</style>

<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="translateTitle('评论编辑')"
    :visible.sync="dialogVisible"
    width="40%"
  >
    <Form
      ref="commentForm"
      :column="column"
      :label-width="0"
      :query="query"
      ref-name="comment"
    />
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveComment">
        {{ translateTitle('确定') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import Form from '@/components/Form'
  import { translateTitle } from '@/utils/i18n'
  import { editorComment } from '@/api/audit'
  export default {
    components: {
      Form,
    },
    data() {
      return {
        dialogVisible: false,
        val: {},
        query: {
          content: null,
        },
        column: [
          {
            prop: 'content',
            type: 'textarea',
            tips: '如需二次编辑评论，请在上方编辑框中编辑。',
          },
        ],
      }
    },
    methods: {
      translateTitle,
      handleOpen(val) {
        this.val = val
        this.query.content = val.content
        this.dialogVisible = true
      },
      // 确定
      async saveComment() {
        const params = {
          content: {
            id: this.val.id,
            content_id: this.val.content_id,
            content: this.query.content,
            voice: this.val.voice.aid,
            voice_duration: this.val.voice_duration,
            pics: this.val.pics,
            video: this.val.video,
          },
        }
        const res = await editorComment(params)
        this.$message({
          type: res.success ? 'success' : 'error',
          message: res.success ? `评论成功` : `评论失败`,
        })
        if (res.success) this.$emit('getTableData')
        this.dialogVisible = false
        this.$refs.commentForm.resetFields()
      },
    },
  }
</script>

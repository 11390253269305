var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.translateTitle("图片详情"),
        visible: _vm.dialogVisible,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("TablePaging", {
        attrs: {
          "column-list": _vm.columnList,
          "is-index": true,
          "is-pagination": false,
          loading: _vm.loading,
          "page-info": _vm.pageInfo,
          "table-data": _vm.list,
        },
        on: { handleCurrentChange: _vm.handleCurrentChange },
        scopedSlots: _vm._u([
          {
            key: "image",
            fn: function (ref) {
              var row = ref.row
              return [
                row.pic.length
                  ? _c("el-image", {
                      attrs: {
                        "preview-src-list": [row.pic[0].url],
                        src: row.pic[0].url,
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "address",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("p", [_vm._v(_vm._s(row.address || "--"))]),
                _c("p", [
                  _vm._v("经度：" + _vm._s(row.baidu_longitude || "--")),
                ]),
                _c("p", [
                  _vm._v("纬度：" + _vm._s(row.baidu_latitude || "--")),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
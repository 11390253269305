<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="translateTitle('操作记录')"
    :visible.sync="dialogVisible"
    width="50%"
  >
    <!-- 表格 -->
    <TablePaging
      :column-list="columnList"
      :is-index="true"
      :is-pagination="false"
      :loading="loading"
      :page-info="pageInfo"
      :table-data="list"
      @handleCurrentChange="handleCurrentChange"
    >
      <!-- 内容 -->
      <template #name="{ row }">
        <div class="content-info">
          <el-image
            v-if="row.head"
            :preview-src-list="[row.head]"
            :src="row.head"
          />
          <p class="content-name">{{ row.name }}</p>
        </div>
      </template>
      <template #create_time="{ row }">
        <span>{{ row.create_time | dateFormat(true) }}</span>
      </template>
    </TablePaging>
  </el-dialog>
</template>

<script>
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import { translateTitle } from '@/utils/i18n'
  import { listThemeActionLog } from '@/api/audit'
  export default {
    components: {
      TablePaging,
    },
    mixins: [tableMixins],
    props: {
      themetype: {
        type: [Number, String],
        default: 1,
      },
    },
    data() {
      return {
        dialogVisible: false,
        columnList: [
          { label: '操作人', slotName: 'name', width: 150 },
          { label: '操作', prop: 'action' },
          { label: '原因', prop: 'remark' },
          { label: '时间', slotName: 'create_time', width: 170 },
        ],
      }
    },
    methods: {
      translateTitle,
      handleOpen(val) {
        this.getTableData(val)
        this.dialogVisible = true
      },
      // 获取操作记录列表
      async getTableData(val) {
        const params = {
          themetype: this.themetype,
          theme_id: val.id,
          is_comment: 1,
        }
        this.loading = true
        const res = await listThemeActionLog(params)
        this.loading = false
        this.list = res.list
      },
    },
  }
</script>
<style lang="scss" scoped>
  $table-info: '.content-info';
  ::v-deep .el-dialog {
    #{$table-info} {
      display: flex;
      align-items: center;
    }
    .content-name {
      margin-left: 10px;
    }
  }
</style>
